#WheelPicker .container {
  display: flex;
  justify-content: center;
  width: 130px;
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
  position: relative;
  font-size: 20px;
}

#WheelPicker .container::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  height: 32px;

  background-color: var(--theme-item-2-hover-color);
  border-radius: 5px;
  pointer-events: none;
  z-index: -1;
}

#WheelPicker .items {
  /* width: 100%; */
  height: 100%;
  padding: 50% 0;
  margin: 0;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

#WheelPicker .items::-webkit-scrollbar {
  display: none;
}

#WheelPicker .item {
  margin-right: 10px;
  list-style-type: none;
  width: 100%;
  text-align: center;
  scroll-snap-align: center;
}

#WheelPicker .item div {
  display: inline-block;
}
