#CollectionSellector .sectionItem {
  display: flex;
  justify-content: space-around;
}

#CollectionSellector img {
  border-radius: 0.5rem;
}

#Applications {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

#Application {
}

@media only screen and (max-width: 1080px) {
  #Applications {
    grid-template-columns: 225px 225px 225px;
    justify-content: center;
  }
  #Application #Thumbnail {
  }
}

@media only screen and (max-width: 840px) {
  #Applications {
    grid-template-columns: 225px 225px;
    justify-content: center;
  }
  #Application #Thumbnail {
  }
}

@media only screen and (max-width: 600px) {
  #Applications {
    grid-template-columns: 175px 175px;
    justify-content: center;
  }
  #Application #Thumbnail {
    height: 175px !important;
  }
}

@media only screen and (max-width: 500px) {
  #Applications {
    grid-template-columns: 150px 150px;
    justify-content: center;
  }
  #Application #Thumbnail {
    height: 150px !important;
  }
}

@media only screen and (max-width: 450px) {
  #Applications {
    grid-template-columns: 100px 100px;
    justify-content: center;
  }
  #Application #Thumbnail {
    height: 100px !important;
  }
}

#Application #Thumbnail {
  border-radius: 0.5rem;
  width: 100%;
  aspect-ratio: 1;
}

#Application #Thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#Application #Thumbnail #NoImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Application #DescriptionArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#Application #CreatedAt {
  display: flex;
  flex-direction: row;
}

#MonsterCollectionImg {
  opacity: 0.3;
}

#HeroCollectionItem:not(:first-child) {
  margin-top: 2rem;
}

#HeroCollectionItem #HeroCollectionName {
  font-size: 16px;
  font-weight: 500;
}

#HerocollectionCharacters {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1rem;
  gap: 1rem;
}

#HeroItem {
  /* width: 18rem; */
  /* height: 121px; */
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  transition: all 0.3s;
  display: grid;
  grid-template-columns: 6rem 1fr;
  padding: 1rem;
  cursor: pointer;
}

#HeroItem:hover {
  background-color: var(--theme-item-2-hover-color);
}

#NoHeroItem {
  /* height: 100%; */
  /* width: 18rem; */
  height: 153px;
  border-width: 1px;
  border-style: dashed;
  border-radius: 0.5rem;
  transition: all 0.3s;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#HeroItem img {
  border-radius: 0.5rem;
}

#HeroItem #Information {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#HeroItem #Controls {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 1rem;
}

#HeroItem #LevelControl {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

#HeroItem #LevelUpComplete {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: green;
  border-radius: 0.5rem;
  height: 3rem;
}

#HeroCollectionInformation {
  margin-top: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  transition: all 0.3s;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

#HeroItem .promotion {
  font-size: 10px;
  height: 15px;
}

#HeroCollectionInformation #Level .emphasis {
  font-size: 20px;
  font-weight: 500;
}

#HeroCollectionInformation #Stats {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left-width: 1px;
  border-left-style: solid;
}

#PromotionLettersPlaceholder {
  display: flex;
  white-space: nowrap;
}

#PromotionLetters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.5rem;
}

#PromotionImgArea {
  position: relative;
}

#PromotionImgArea img {
  width: 2.5rem;
}

#PromotionDescription {
  position: absolute;
  bottom: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  background-color: black;
  font-size: 10px;
}

#HeroCollectionItem #HeroCollectionStats .itemBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
}

#HeroCollectionItem #HeroCollectionStats .item {
  /* width: 6rem; */
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0.5rem;
  transition: all 0.3s;
}
#HeroCollectionItem #HeroCollectionStats .item:hover {
  background-color: var(--theme-item-2-hover-color);
}

#MyParty .dragDropBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

#MyField {
  margin-top: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  transition: all 0.3s;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

#MyField #WheelPicker {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ExpTime {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

#ExpHour,
#ExpMinute,
#ExpNumber,
#ExpGain {
  height: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
  position: relative;
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
}

#ExpHour input,
#ExpMinute input,
#ExpNumber input {
  font-size: 20px;
  background-color: transparent;
  text-align: center;
  width: 5rem;
}
#ExpGain input {
  font-size: 20px;
  background-color: transparent;
  text-align: center;
  width: 12rem;
}

#ExpHour::after,
#ExpMinute::after,
#ExpNumber::after,
#ExpGain::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  height: 32px;

  background-color: var(--theme-item-2-hover-color);
  border-radius: 5px;
  pointer-events: none;
  z-index: -1;
}

#ExpHour input[type="number"]::-webkit-outer-spin-button,
#ExpHour input[type="number"]::-webkit-inner-spin-button,
#ExpMinute input[type="number"]::-webkit-outer-spin-button,
#ExpMinute input[type="number"]::-webkit-inner-spin-button,
#ExpNumber input[type="number"]::-webkit-outer-spin-button,
#ExpNumber input[type="number"]::-webkit-inner-spin-button,
#ExpGain input[type="number"]::-webkit-outer-spin-button,
#ExpGain input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#ExpHour input[type="number"],
#ExpMinute input[type="number"],
#ExpNumber input[type="number"],
#ExpGain input[type="number"] {
  -moz-appearance: textfield;
}

#ExpPerMin {
  width: 8rem;
  position: relative;
}

#ExpPerMin #SaveButtonArea {
  position: absolute;
  width: 100%;
  bottom: 0;
}

#ExpPerMin #SaveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
  background-color: hsl(218, 70%, 50%);
  color: white;
  font-weight: 600;
  transition: all 0.3s;
}
#ExpPerMin #SaveButton:hover {
  background-color: hsl(218, 70%, 55%);
}

#SavedFields {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

#SavedFields div {
  text-align: center;
}

#SavedFields .columns {
  display: grid;
  grid-template-columns: 1.5rem 2fr 1fr 1fr;
  width: calc(100% - 1rem);
  height: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

#SavedFields .container {
  width: calc(100% - 1rem);
  height: calc(100% - 5rem);
  /* height: 100px; */
  /* height: 84px; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
}

#MyFieldItem {
  display: grid;
  grid-template-columns: 1.5rem 2fr 1fr 1fr;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: rgb(6 24 44 / 22%) 0px 0px 0px 2px,
    rgb(6 24 44 / 38%) 0px 3px 3px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: all 0.3s;
}

#MyFieldItem:hover {
  background-color: var(--theme-item-2-hover-color);
}

#SavedFields .footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 1rem);
  height: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

#SavedFields #DeleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
  background-color: hsl(4, 75%, 50%);
  color: white;
  font-weight: 600;
  transition: all 0.3s;
}

#SavedFields #DeleteButton:hover {
  background-color: hsl(4, 75%, 60%);
}
