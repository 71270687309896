#LoginPage {
  min-height: calc(100vh - 6rem - 2rem - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

#Login {
  width: 300px;
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  padding: 2rem;
  transition: all 0.3s;
}

#Login form {
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: 2rem;
  padding-top: 2rem;
}

#Login #Title {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

#Login input {
  width: calc(100% - 26px);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  height: 1.5rem;
  padding: 6px 12px;
  transition: border 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

#Login input:hover,
#Login input:focus,
#Login input:active {
  border-color: hsl(218, 60%, 50%);
}

#Login #LoginButton {
  height: 3rem;
  width: 100%;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 0.5rem;
  background: hsl(218, 60%, 50%);
  color: white;
  opacity: 90%;
}

#Login #LoginButton:hover {
  opacity: 100%;
}
