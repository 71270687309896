#Navigation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#NavigationItem {
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  opacity: 80%;
}

#NavigationItem a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: all 0.3s;
}

#NavigationItem a:hover {
  color: hsl(200, 80%, 80%);
}

@media only screen and (max-width: 600px) {
  #NavigationItem a,
  #HeaderOptionArea #LoginButton,
  #HeaderOptionArea #LogoutButton {
    font-size: 8px;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
