#BossTimer {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

#CurrentTime {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#CurrentTime #Date {
  font-size: 24px;
}

#CurrentTime #Time {
  font-size: 48px;
}

#BossTimerBrief {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#BossTimerBrief .item .imageBox {
  position: relative;
}

#BossTimerBrief img {
  width: 320px;
  /* height: 300px; */
  border-radius: 0.25rem;
}

#BossTimerBrief .date {
  font-size: 16px;
  font-weight: 500;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: orange;
}

#BossTimerBrief .date:nth-child(3) {
  color: rgb(133, 119, 219);
  margin-top: 2rem;
}

#BossTimerBrief .title {
  /* margin-top: 1rem; */
  font-size: 16px;
  font-weight: 500;
  padding: 1rem;
  text-align: center;
}

#BossTimerBrief .bossTimeArea {
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

#BossTimerBrief .bossTimeItem {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  font-size: 14px;
  /* font-size: 20px; */
}

/* #date1,
#date2,
#date5,
#date6 {
  opacity: 0;
}
#date3,
#date4 {
} */

@media only screen and (max-width: 600px) {
  #BossTimerBrief {
    flex-direction: column;
    gap: 2rem;
  }
  #BossTimerBrief img {
    width: 100%;
  }
  #date1,
  #date2,
  #date5,
  #date6 {
    opacity: 1;
  }
  #BossTimer #ㅌㄷㅇㄹ {
    border-radius: 0.5rem;
    width: 100%;
  }
}

#BossTimer #ㅌㄷㅇㄹ {
  border-radius: 0.5rem;
}

.bossDurationHours {
  right: 0.5rem;
  bottom: 0.5rem;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: hsl(0, 0%, 10%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed turquoise;
  font-size: 14px;
  font-weight: 600;
}
