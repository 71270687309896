#Header {
  height: 6rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  transition: all 0.3s;
}

#HeaderLogoArea {
  /* width: 400px; */
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 1rem;
}
#HeaderLogoArea img {
  width: 100px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
#HeaderLogoDescription {
  font-size: 16px;
  font-weight: 600;
}
#HeaderNavigationArea {
  display: flex;
  align-items: center;
}
#HeaderOptionArea {
  display: flex;
  align-items: center;
}

#HeaderOptionArea svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 2rem;
  margin-right: 2rem;
  transition: 0.3s ease-out;
}

#HeaderOptionArea #LoginBox {
  height: 100%;
}

#HeaderOptionArea #LoginButton,
#HeaderOptionArea #LogoutButton {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  #Header {
    height: 4rem;
  }
  #HeaderLogoDescription {
    display: none;
  }
  #HeaderLogoArea {
    padding: 0 0 0 1rem;
  }
  #HeaderLogoArea img {
    width: 50px;
  }
  #HeaderOptionArea svg {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
