#ReplyComponent input,
#ReplyComponent textArea {
  font-size: 12px;
}

#ReplyComponent #BorderBetween {
  border-width: 1px;
  border-style: solid;
}

#RepliesItem {
  width: calc(100% - 2rem);
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  padding: 1rem;
  position: relative;
}

#RepliesItem:nth-child(n + 2) {
  margin-top: 1rem;
}

#RepliesItem .writerIP {
  margin-left: 0.5rem;
  opacity: 0.6;
}

#RepliesItem .content {
  font-size: 16px;
  word-break: break-all;
  word-wrap: break-word;
  margin: 0.25rem 0;
}

#RepliesItem .date {
  opacity: 60%;
}

#RepliesItem .buttonArea {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

#RepliesItem .buttonArea input {
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  width: 4rem;
  height: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
}

#RepliesItem textarea {
  margin-top: 1rem;
  background-color: transparent;
  resize: none;
  width: calc(100% - 1rem);

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

#Reply {
  margin-top: 2rem;
  width: calc(100% - 2rem);
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.75rem 1rem 1rem 1rem;
  /* padding: 0.5rem; */
}

#Reply #Writer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

#Reply #Writer .information {
  display: flex;
  white-space: nowrap;
}

#Reply #Writer input {
  height: 20px;
  line-height: 20px;
  background-color: transparent;
}

#Reply #Content textarea {
  margin-top: 1rem;
  background-color: transparent;
  resize: none;
  width: 100%;
}

#Reply .buttonArea {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
}

#SubmitButton {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 1.5rem;
  transition: all 0.3s;
  background-color: transparent;
  cursor: pointer;
}

#SubmitButton:hover {
  background-color: var(--theme-item-2-hover-color);
}

.submitButtonColorDarkRed {
  background-color: hsla(0, 50%, 40%, 0.5) !important;
}

.submitButtonColorDarkRed:hover {
  background-color: hsla(0, 50%, 40%, 0.8) !important;
}
