#SummonCharm img {
  border-radius: 0.5rem;
}
.summonCharmItemName {
  font-size: 24px;
}
#CharmImage1 {
  margin-top: 1rem;
  width: 20rem;
}
