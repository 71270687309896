@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 100;
  src: url('./NotoSansKR/NotoSansKR-Thin.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Thin.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 200;
  src: url('./NotoSansKR/NotoSansKR-ExtraLight.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Light.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 300;
  src: url('./NotoSansKR/NotoSansKR-Light.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Light.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: url('./NotoSansKR/NotoSansKR-Regular.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Regular.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500;
  src: url('./NotoSansKR/NotoSansKR-Medium.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Medium.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 600;
  src: url('./NotoSansKR/NotoSansKR-SemiBold.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-SemiBold.ttf') format('ttf'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: url('./NotoSansKR/NotoSansKR-Bold.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Bold.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 800;
  src: url('./NotoSansKR/NotoSansKR-ExtraBold.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-ExtraBold.woff') format('woff'); */
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 900;
  src: url('./NotoSansKR/NotoSansKR-Black.ttf') format('truetype');
  /* src: url('./NotoSansKR/NotoSansKR-Black.woff') format('woff'); */
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('./Roboto/Roboto-Bold.ttf') format('truetype');
}
