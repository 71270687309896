#Category:not(:first-child) {
  margin-top: 1rem;
}

#Articles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 1rem;
  gap: 1rem;
}

@media only screen and (max-width: 1080px) {
  #Articles {
    grid-template-columns: 225px 225px 225px;
    justify-content: center;
  }
  #Article #Thumbnail {
  }
}

@media only screen and (max-width: 840px) {
  #Articles {
    grid-template-columns: 225px 225px;
    justify-content: center;
  }
  #Article #Thumbnail {
  }
}

@media only screen and (max-width: 600px) {
  #Articles {
    grid-template-columns: 175px 175px;
    justify-content: center;
  }
  #Article #Thumbnail {
    height: 175px !important;
  }
}

@media only screen and (max-width: 430px) {
  #Articles {
    grid-template-columns: 150px 150px;
    justify-content: center;
  }
  #Article #Thumbnail {
    height: 150px !important;
  }
}

@media only screen and (max-width: 380px) {
  #Articles {
    grid-template-columns: 125px 125px;
    justify-content: center;
  }
  #Article #Thumbnail {
    height: 125px !important;
  }
}

#Article #Thumbnail {
  border-radius: 0.5rem;
  width: 100%;
  aspect-ratio: 1;
}

#Article #Thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#Article #Thumbnail #NoImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Article #DescriptionArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#Article #Subject {
  font-size: 16px;
}

#Article #Writer,
#Article #CreatedAt,
#Article #ReadCount {
  font-size: 12px;
  opacity: 60%;
}

#Article #CreatedAt {
  display: flex;
  flex-direction: row;
}

#WriteButtonArea Button {
  height: 3rem;
  width: 100%;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 0.5rem;
  background: hsl(0, 0%, 50%);
  color: white;
  opacity: 90%;
}

#WriteButtonArea Button:hover {
  opacity: 100%;
}
#Write {
  position: relative;
}
#Write #SubjectInput {
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  box-sizing: border-box; /* 테두리와 패딩을 포함한 크기 계산 */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 테두리와 그림자 전환 효과 설정 */
}

#SubjectInput:focus {
  border-color: #006ce7; /* 포커스 시 테두리 색상 변경 */
  box-shadow: 0 0 0 1px #006ce7; /* 포커스 시 그림자를 사용하여 두께 효과 */
  outline: none; /* 포커스 시 기본 outline 제거 */
}

/* #Write #SubjectInput:focus {
  border: 2px solid hsl(218, 70%, 60%);
} */

/* #Write #SubjectInputBefore {
  border: 2px solid rgba(255, 255, 255, 0);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  transition: border 0.3s;
}

#Write #SubjectInputBefore:hover {
  border-color: rgba(25, 25, 255, 1);
} */

#Write #ButtonArea {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#Write #ButtonArea #ThumbnailUploader {
  width: 225px;
  height: 225px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}

#Write #ButtonArea #ThumbnailUploader #children {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Write #ButtonArea #ThumbnailUploader #children img {
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  padding: 0.5rem;
  object-fit: cover;
}

#Write #SubmitButton {
  padding: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 0.5rem;
  background: hsl(0, 0%, 25%);
  color: white;
  opacity: 90%;
}

#Write #SubmitButton:hover {
  opacity: 100%;
}

#CategorySelectArea {
  position: relative;
  z-index: 10000;
}

#Viewer #SubjectArea {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}

#SubjectRightArea {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

#CopyButton {
  cursor: pointer;
}

#Viewer #Subject {
  font-size: 20px;
}

#ReadCount {
  display: flex;
  justify-content: center;
  align-self: center;
}

#Viewer #Content {
  margin-top: 1rem;
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  font-size: 14px;
}

/* #Viewer #ThumbnailImage {
  width: 225px;
  height: 225px;
} */

#Viewer #ButtonArea {
  padding-top: 2rem;
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 1rem;
}

#Viewer #ButtonArea #DeleteButton,
#Viewer #ButtonArea #ListButton,
#Viewer #ButtonArea #ReviseButton {
  width: 5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition: all 0.3s;
  font-weight: 600;
  color: white;
  font-size: 14px;
  cursor: pointer;
}
#Viewer #ButtonArea #ListButton {
  background-color: hsl(218, 70%, 50%);
}
#Viewer #ButtonArea #ListButton:hover {
  background-color: hsl(218, 70%, 55%);
}
#Viewer #ButtonArea #ReviseButton {
  background-color: hsl(20, 75%, 50%);
}
#Viewer #ButtonArea #ReviseButton:hover {
  background-color: hsl(20, 75%, 60%);
}
#Viewer #ButtonArea #DeleteButton {
  background-color: hsl(4, 75%, 50%);
}
#Viewer #ButtonArea #DeleteButton:hover {
  background-color: hsl(4, 75%, 60%);
}

/* 로딩 오버레이 스타일 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
