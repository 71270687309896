/* Initialize */

a {
  text-decoration: none;
  color: inherit;
}
a:link,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

input,
textarea {
  outline: none;
  border: none;
}

p {
  margin: 0;
}

/* Fonts */

.fontSize10px {
  font-size: 10px;
}

.fontSize12px {
  font-size: 12px;
}

.fontSize24px {
  font-size: 24px;
}

.fontColorWhite {
  color: white;
}

.fontColorCCC {
  color: #ccc;
}

.fontColorGrey {
  color: #8a8a8a;
}

.fontColorRed {
  color: red;
}

.fontColorBasic {
  color: white;
}

.fontColorRecruit {
  color: hsl(90, 100%, 35%);
}

.fontColorCollect {
  color: hsl(216, 70%, 55%);
}

.fontColorVeteran {
  color: hsl(280, 60%, 70%);
}

.fontColorHighlight {
  color: #006ce7;
}

.fontColorComplete {
  color: hsl(110, 100%, 45%);
}

/* features */

.opacity30 {
  opacity: 0.3;
}
.opacity70 {
  opacity: 0.7;
}

/* Alignments */

.flex {
  display: flex;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
}

.flexStartCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexCenterAll {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexCenterStart {
  display: flex;
  justify-content: center;
  align-items: start;
}

.flexDirectionRow {
  flex-direction: row;
}

.flexDirectionColumn {
  flex-direction: column;
}

.textCenter {
  text-align: center;
}

/* Margin */

.mt-half {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-qhalf {
  margin-left: 0.25rem;
}
.ml-half {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 1rem;
}

/* Padding */

.pb-6 {
  padding-bottom: 6px;
}

/* Length */

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

/* Themes */

.themeBackgroundColor {
  background-color: var(--theme-background-color);
}

.themeItem1Color {
  background-color: var(--theme-item-1-color);
}

.themeItem1BlurColor {
  background-color: var(--theme-item-1-Blur-color);
}

.themeItem2Color {
  background-color: var(--theme-item-2-color);
}

.themeItem2HoverColor {
  background-color: var(--theme-item-2-hover-color);
}

.themeBorder1Color {
  border-color: var(--theme-border-1-color);
}

.themeBorder2Color {
  border-color: var(--theme-border-2-color);
}

.themeButtonShadow {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.themeFontColor {
  color: var(--theme-font-color);
}

.themeToggleButtonColor {
  color: var(--theme-toggle-button-color);
}

.themeHighlightItem {
  background-color: #006ce7;
  color: white;
}

.themeHighlightItem:hover {
  background-color: #2983e9 !important;
  color: white;
}

/* Page */

#App {
  transition: all 0.3s;
}

#Header,
#MainPage,
#Footer {
  width: 100%;
  font-family: "Noto Sans KR";
  font-size: 12px;
}

#Header {
  backdrop-filter: saturate(120%) blur(5px);
}

#MainPage {
  min-height: calc(100vh - 6rem - 2rem);
  padding-top: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  /* transition: 0.3s all ease-out; */
}

#Footer {
  width: calc(100% - 2rem);
  height: 2rem;
  background-color: hsl(0, 0%, 10%);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.wrapper {
  /* width: calc(1080px - 4rem); */
  width: 1080px;
  padding: 2rem;
  overflow: auto;
  /* border-left: 1px solid white;
  border-right: 1px solid white; */
}

.sectionItem {
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 2rem;
  transition: all 0.3s;
}

#ScrollButtonArea {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  opacity: 0.9;
}

#ScrollToggleButton {
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

#ScrollButtonWrapper {
  max-height: 0px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow: auto;
}

#ScrollButtonWrapper.expanded {
  max-height: calc(100vh - 8rem);
}

#ScrollButton {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #MainPage {
    padding-top: 4rem;
  }
  #Footer {
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .wrapper {
    padding: 1rem;
  }
  .sectionItem {
    padding: 1rem;
  }
}

/* progressBar */

.progress {
  width: 13rem;
  height: 1rem;
  background: #ddd;
  border-radius: 8px;
  font-size: 11px;
  text-align: center;
  position: relative;
}

.progressBar {
  height: 100%;
  background-color: #006ce7;
  border-radius: 8px;
  transition: all ease 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.progressContext {
  position: absolute;
  text-align: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.progressName {
  font-size: 12px;
  width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
}

.progressUploaded {
  font-size: 12px;
}

/* others */

#RecommandationBadge {
  background-color: hsl(15, 90%, 55%);
  color: white;
  border-radius: 0.25rem;
  border: 1px solid white;
  font-size: 8px;
  padding: 1px;
  margin-left: 0.5rem;
}

.swal2-backdrop-show {
  z-index: 10000 !important; /* 뒷배경 z-index 설정 */
}

.transition {
  transition: all 0.3s;
}

.scrollMargin {
  scroll-margin: calc(97px + 1rem);
}
